const React = require("react");
const PropTypes = require("prop-types");
const constants = require("wp-constants").spacerFree;
const Link = require("react-router-dom").Link;
const ExternalLink = require("../links/ExternalLink.jsx");
const LanguageSelector = require("./LanguageSelector.jsx");
const { withTranslation } = require("react-i18next");
const GALabels = require("../../../spacerFree/optionsConfig/googleAnalytics.labels.json");
const ga = require("../../../shared/tracking");

function Footer(props) {
  React.useEffect(() => {
    console.log(typeof window.OneTrust?.initializeCookiePolicyHtml)
    if(typeof window.OneTrust?.initializeCookiePolicyHtml === 'function') {
      window.OneTrust?.initializeCookiePolicyHtml();
    }
  },[window.OneTrust]);
      
  return (
    <footer role="contentinfo" className="footer content is-small">
      <div className="container">
        <div className="columns has-margin-top-2 has-no-margin-top-mobile">
          <div className="column is-one-quarter">
            <a href="/">
              <img
                src="/svg/logos/dark-cbre-logo-spacer-by-cbre.svg"
                alt={props.t("footerCBRELogoAlt")}
                className="has-margin-bottom-2 has-padding-right-3 has-no-margin-bottom-mobile"
              />
            </a>
            <div className="social-media-icons">
              <ExternalLink href="https://www.facebook.com/cbre/">
                <img
                  className="has-margin-right-4"
                  src="/svg/icons/social-media/facebook-social-icon-spacer-by-cbre.svg"
                  alt={props.t("footerFacebookIconAlt")}
                />
              </ExternalLink>

              <ExternalLink href="https://twitter.com/CBREworkplace">
                <img
                  className="has-margin-right-4"
                  src="/svg/icons/social-media/twitter-social-icon-spacer-by-cbre.svg"
                  alt={props.t("footerTwitterIconAlt")}
                />
              </ExternalLink>

              <ExternalLink href={props.t("footerLinkedinLink")}>
                <img
                  className="has-margin-right-4"
                  src="/svg/icons/social-media/linkedin-social-icon-spacer-by-cbre.svg"
                  alt={props.t("footerLinkedinIconAlt")}
                />
              </ExternalLink>
            </div>
          </div>
          <div className="column is-three-quarters">
            <div className="columns is-mobile is-multiline">
              <div className="column is-one-quarter-desktop is-half-mobile">
                <div className="footer-header">
                  {props.t("footerHeaderQuestionsText")}
                </div>
                <a href={`mailto:${props.t("spacerSupportEmail")}`}>
                  <span className="footer-link">
                    {constants.contact.SPACER_SUPPORT_EMAIL}
                  </span>
                </a>
              </div>
              <div className="column is-one-quarter-desktop is-half-mobile">
                <div className="footer-header">
                  {props.t("footerHeaderAboutText")}
                </div>
                <Link to="/about-spacer-by-cbre">
                  <span className="footer-link">
                    {props.t("footerLinkWhatIsSpacerText")}
                  </span>
                </Link>
                <Link to="/insights-spacer-by-cbre">
                  <span className="footer-link">
                    {props.t("footerLinkBlogText")}
                  </span>
                </Link>
                <ExternalLink href={props.t("footerWorkplaceLink")}>
                  <span className="footer-link">
                    {props.t("footerLinkWorkplaceText")}
                  </span>
                </ExternalLink>
                {props.lng == "de" && (
                  <ExternalLink href={props.t("footerWorkplaceLinkForDE")}>
                    <span className="footer-link">
                      {props.t("footerLinkWorkplaceTextForDE")}
                    </span>
                  </ExternalLink>
                )}
              </div>
              <div className="column is-one-quarter-desktop is-half-mobile terms-and-privacy-footer-section">
                <div className="footer-header">
                  {props.t("footerHeaderMoreText")}
                </div>
                <LanguageSelector />
                <Link to="/terms-spacer-by-cbre">
                  <span className="footer-link">
                    {props.t("footerLinkTermsText")}
                  </span>
                </Link>
                <ExternalLink href={props.t("footerPrivacyLink")}>
                  <span className="footer-link">
                    {props.t("footerLinkPrivacyText")}
                  </span>
                </ExternalLink>
                <span id="ot-sdk-btn" className="ot-sdk-show-settings footer-cookie-link">
                  Cookie Settings
                </span>
              </div>
              <div className="column is-one-quarter-desktop is-half-mobile">
                <div className="footer-header">
                  {props.t("footerHeaderBrokersText")}
                </div>
                <Link
                  to="/referral"
                  onClick={() => {
                    ga.GAEvent(
                      GALabels.categories.referals,
                      GALabels.actions.referal,
                      GALabels.labels.referal
                    );
                  }}
                >
                  <span className="footer-link">
                    {props.t("footerLinkReferralText")}
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      
      
    </footer>
  );
}

Footer.propTypes = {
  t: PropTypes.func.isRequired,
};

module.exports = withTranslation("common")(Footer);
